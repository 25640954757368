// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Navbar {
    height: 80px;
    width: 100%;
    background-color: #e1604d;
}

.NavBtn {
    text-decoration: none;
    font-size: 1.2rem;
}

.NavTitle {
    height: 80px;
    left: 75%;
    font-size: 2.5rem;
    color: white;
    font-family: "Poetsen One", sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/components/navbar/Navbar.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,yBAAyB;AAC7B;;AAEA;IACI,qBAAqB;IACrB,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,SAAS;IACT,iBAAiB;IACjB,YAAY;IACZ,sCAAsC;AAC1C","sourcesContent":[".Navbar {\n    height: 80px;\n    width: 100%;\n    background-color: #e1604d;\n}\n\n.NavBtn {\n    text-decoration: none;\n    font-size: 1.2rem;\n}\n\n.NavTitle {\n    height: 80px;\n    left: 75%;\n    font-size: 2.5rem;\n    color: white;\n    font-family: \"Poetsen One\", sans-serif;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
