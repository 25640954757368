// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Button {
    background-color: white;
    color: white;
    padding: 5px 15px;
    border-radius: 5px;
    outline: 0;
    border: 0; 
    cursor: pointer;
    box-shadow: 0px 2px 2px lightgray;
}

.BtnText {
    text-decoration: none;
    color: #e1604d;
    font-size: 1.2rem;
    font-weight: bold;
    font-family: 'Source Code Pro', monospace;
}`, "",{"version":3,"sources":["webpack://./src/components/button/Button.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,UAAU;IACV,SAAS;IACT,eAAe;IACf,iCAAiC;AACrC;;AAEA;IACI,qBAAqB;IACrB,cAAc;IACd,iBAAiB;IACjB,iBAAiB;IACjB,yCAAyC;AAC7C","sourcesContent":[".Button {\n    background-color: white;\n    color: white;\n    padding: 5px 15px;\n    border-radius: 5px;\n    outline: 0;\n    border: 0; \n    cursor: pointer;\n    box-shadow: 0px 2px 2px lightgray;\n}\n\n.BtnText {\n    text-decoration: none;\n    color: #e1604d;\n    font-size: 1.2rem;\n    font-weight: bold;\n    font-family: 'Source Code Pro', monospace;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
