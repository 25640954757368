// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LogoUnbored {
    width: 350px;
    height: auto; 
    animation: fadeIn 0.5s forwards; 
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: scale(0.8);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.ShowcaseTitle {
    font-size: 2.5rem;
    font-family: "Poetsen One", sans-serif;
    color: #e1604d;
    opacity: 0;
    animation: fadeIn 0.5s forwards; 
}

.ShowcaseText {
    font-size: 1.5rem;
    font-family: "Poetsen One", sans-serif;
    animation: fadeIn 0.5s forwards;
}

.ShowcaseBody {
    height: 70vh;
}


.ShowcaseBodyMid {
    height: 120vh;
}


.ShowcaseBodyBig {
    height: 150vh;
}

.carousel {
    width:35%;
    height: auto;
    margin: auto;
    img {
        width: 100%;
        height: auto;
    }
}

.ShowcasePresCard {
    /* height: 80%; */
    background-color: white;
    box-shadow: 0 2px 2px lightgray;
    border-radius: 5px;
    transition: transform 0.3s, box-shadow 0.3s;
    cursor: pointer;
    img {
        width: 100%;
        height: auto;
    }
}

.ShowcasePresCard.clicked {
    transform: scale(1.05);
    box-shadow: 0 4px 4px lightgray;
}`, "",{"version":3,"sources":["webpack://./src/pages/vitrine/Vitrine.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;IACZ,+BAA+B;AACnC;;AAEA;IACI;QACI,UAAU;QACV,qBAAqB;IACzB;IACA;QACI,UAAU;QACV,mBAAmB;IACvB;AACJ;;AAEA;IACI,iBAAiB;IACjB,sCAAsC;IACtC,cAAc;IACd,UAAU;IACV,+BAA+B;AACnC;;AAEA;IACI,iBAAiB;IACjB,sCAAsC;IACtC,+BAA+B;AACnC;;AAEA;IACI,YAAY;AAChB;;;AAGA;IACI,aAAa;AACjB;;;AAGA;IACI,aAAa;AACjB;;AAEA;IACI,SAAS;IACT,YAAY;IACZ,YAAY;IACZ;QACI,WAAW;QACX,YAAY;IAChB;AACJ;;AAEA;IACI,iBAAiB;IACjB,uBAAuB;IACvB,+BAA+B;IAC/B,kBAAkB;IAClB,2CAA2C;IAC3C,eAAe;IACf;QACI,WAAW;QACX,YAAY;IAChB;AACJ;;AAEA;IACI,sBAAsB;IACtB,+BAA+B;AACnC","sourcesContent":[".LogoUnbored {\n    width: 350px;\n    height: auto; \n    animation: fadeIn 0.5s forwards; \n}\n\n@keyframes fadeIn {\n    0% {\n        opacity: 0;\n        transform: scale(0.8);\n    }\n    100% {\n        opacity: 1;\n        transform: scale(1);\n    }\n}\n\n.ShowcaseTitle {\n    font-size: 2.5rem;\n    font-family: \"Poetsen One\", sans-serif;\n    color: #e1604d;\n    opacity: 0;\n    animation: fadeIn 0.5s forwards; \n}\n\n.ShowcaseText {\n    font-size: 1.5rem;\n    font-family: \"Poetsen One\", sans-serif;\n    animation: fadeIn 0.5s forwards;\n}\n\n.ShowcaseBody {\n    height: 70vh;\n}\n\n\n.ShowcaseBodyMid {\n    height: 120vh;\n}\n\n\n.ShowcaseBodyBig {\n    height: 150vh;\n}\n\n.carousel {\n    width:35%;\n    height: auto;\n    margin: auto;\n    img {\n        width: 100%;\n        height: auto;\n    }\n}\n\n.ShowcasePresCard {\n    /* height: 80%; */\n    background-color: white;\n    box-shadow: 0 2px 2px lightgray;\n    border-radius: 5px;\n    transition: transform 0.3s, box-shadow 0.3s;\n    cursor: pointer;\n    img {\n        width: 100%;\n        height: auto;\n    }\n}\n\n.ShowcasePresCard.clicked {\n    transform: scale(1.05);\n    box-shadow: 0 4px 4px lightgray;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
