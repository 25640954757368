// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
    /* text-align: center; */
    background: whitesmoke;
    display: flex;
    height: auto;
    align-items: center;
    justify-content: center;
  }
  .text-button {
    text-decoration: none;
    
  }
  .logo {
    width: 24px;
    height: 24px;
    margin-right: 1rem;
  }
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: whitesmoke;
    /* min-height: 100vh; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  :root {
    --mainColor: #E1604D;
    --textColor: whitesmoke;
  }
  
  .App-link {
    color: var(--mainColor);
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(180deg);
    }
  }
  
.chakra-input {
  padding-right: 15px !important;
}`, "",{"version":3,"sources":["webpack://./src/pro/styles/ProLoginRegister.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,sBAAsB;IACtB,aAAa;IACb,YAAY;IACZ,mBAAmB;IACnB,uBAAuB;EACzB;EACA;IACE,qBAAqB;;EAEvB;EACA;IACE,WAAW;IACX,YAAY;IACZ,kBAAkB;EACpB;EACA;IACE,cAAc;IACd,oBAAoB;EACtB;;EAEA;IACE;MACE,4CAA4C;IAC9C;EACF;;EAEA;IACE,4BAA4B;IAC5B,uBAAuB;IACvB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,6BAA6B;IAC7B,YAAY;EACd;;EAEA;IACE,oBAAoB;IACpB,uBAAuB;EACzB;;EAEA;IACE,uBAAuB;EACzB;;EAEA;IACE;MACE,uBAAuB;IACzB;IACA;MACE,yBAAyB;IAC3B;EACF;;AAEF;EACE,8BAA8B;AAChC","sourcesContent":[".App {\n    /* text-align: center; */\n    background: whitesmoke;\n    display: flex;\n    height: auto;\n    align-items: center;\n    justify-content: center;\n  }\n  .text-button {\n    text-decoration: none;\n    \n  }\n  .logo {\n    width: 24px;\n    height: 24px;\n    margin-right: 1rem;\n  }\n  .App-logo {\n    height: 40vmin;\n    pointer-events: none;\n  }\n  \n  @media (prefers-reduced-motion: no-preference) {\n    .App-logo {\n      animation: App-logo-spin infinite 20s linear;\n    }\n  }\n  \n  .App-header {\n    background-color: whitesmoke;\n    /* min-height: 100vh; */\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    font-size: calc(10px + 2vmin);\n    color: white;\n  }\n  \n  :root {\n    --mainColor: #E1604D;\n    --textColor: whitesmoke;\n  }\n  \n  .App-link {\n    color: var(--mainColor);\n  }\n  \n  @keyframes App-logo-spin {\n    from {\n      transform: rotate(0deg);\n    }\n    to {\n      transform: rotate(180deg);\n    }\n  }\n  \n.chakra-input {\n  padding-right: 15px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
